import axios from "axios";

let SERVER = window.location.origin;
const Instance = axios.create({
  // baseURL: "http://localhost:4600/api",
  baseURL: `${SERVER}/api`,
  // baseURL: "https://idc.amazonusa.link/api",
});

export default Instance;
