import React, { useEffect, useState } from "react";

// API
import {
  GetDeviceData,
  DeleteDeviceAPI,
  GetDeviceHourlyReportData,
  GetDevices,
  GetDeviceDailyReportData,
  GetLastDayReportData,
  GetDeviceHourReportData,
} from "../../Service/DeviceAPI";
import { useLocation, useNavigate } from "react-router-dom";

// Helper
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiInformationLine } from "react-icons/ri";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

// Styling
import "./Device.scss";

// Other Components
import { useSelector } from "react-redux";
import { Button, Input, Spin } from "antd";
import ROLES from "../../Utils/Roles";
import ConfirmationModel from "../Common/ConfirmationModel/ConfirmationModel";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import { DateRangePicker } from "rsuite";
import { GetEmail, addEmail } from "../../Service/Email";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";

// ------------------------------------------Start Function------------------------

export default function Device() {
  // Searching the data from start to end date------------
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [lastDayData, setLastDayData] = useState([]);
  const [lastDayReport, setLastDayReport] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    deviceId: null,
    loading: false,
  });

  const dataLastDay = lastDayReport[0];
  const latestValue = dataLastDay?.[dataLastDay?.length - 1];

  // let countFuelConsumed = 0
  // for(let i=0; i<dataLastDay?.length; i+=1){
  //   const fuelConsumed = dataLastDay[i]?.fuelconsumed
  //   countFuelConsumed += parseInt(fuelConsumed)
  // }

  // let countRefueling = 0
  // for(let i=0; i<dataLastDay?.length; i+=1){
  //   const Refueling = dataLastDay[i]?.refueling
  //   countRefueling += parseInt(Refueling)
  // }

  // let countPilferage = 0
  // for(let i=0; i<dataLastDay?.length; i+=1){
  //   const Pilferage = dataLastDay[i]?.pilferage
  //   countPilferage += parseInt(Pilferage)
  // }

  // let countGenSetRunTime = 0
  // for(let i=0; i<dataLastDay?.length; i+=1){
  //   const GenSetRunTime = dataLastDay[i]?.gensetruntime
  //   countGenSetRunTime += parseInt(GenSetRunTime)
  // }

  let countFuelConsumed =
    lastDayData[lastDayData?.length - 1]?.totalfuelconsumed -
    lastDayData?.[0]?.totalfuelconsumed;
  let countRefueling =
    lastDayData[lastDayData?.length - 1]?.totalrefueling -
    lastDayData?.[0]?.totalrefueling;
  let countPilferage =
    lastDayData[lastDayData?.length - 1]?.totalpilferage -
    lastDayData?.[0]?.totalpilferage;
  let countGenSetRunTime =
    lastDayData[lastDayData?.length - 1]?.totalGensetRunTime -
    lastDayData?.[0]?.totalGensetRunTime;

  const genSetRunInHour = countGenSetRunTime / 60;
  let averageFuel = countFuelConsumed / genSetRunInHour;
  const firstDevice = deviceData[0];
  const machine = location?.state?.machine;
  const buildingId = location?.state?.buildingId;
  const departmentId = location?.state?.departmentId;
  const buildingName = location?.state?.buildingName;
  const departmentName = location?.state?.departmentName;

  let machineId = machine?._id;

  const gettingDeviceData = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetDeviceData(payload);
    if (res?.error != null) {
      toast.error(res?.error);
    } else {
      setDeviceData(res?.data?.result);
      if (res?.data?.result[0]) {
        const deviceData = res?.data?.result[0];
        const latestDate = deviceData[deviceData?.length - 1]?.datetime;
        if (latestDate) {
          const lastDate = new Date(latestDate);
          lastDate.setDate(lastDate.getDate() - 1);
          lastDate.setUTCHours(0, 0, 0, 0);
          let payload = {
            machineId: machineId,
            startDate: new Date(lastDate),
            endDate: new Date(lastDate),
          };

          let res = await GetDeviceHourReportData(payload);
          if (res?.error != null) {
            setLoading(false);
            toast.error(res.error);
          } else {
            setLastDayData(res?.data?.result);
            return true;
          }
        }
      }
    }
    setLoading(false);
  };
  const gettingDevices = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetDevices(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDevices(res?.data?.result);
    }
    setLoading(false);
  };

  const gettingLastDay = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
    };
    let res = await GetLastDayReportData(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setLastDayReport(res?.data?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    gettingDeviceData();
    gettingDevices();
    gettingLastDay();
  }, [refreshPage]);

  // Getting User Data
  const userData = useSelector((state) => state?.userData);

  const handleAddDevice = () => {
    navigate("/device/add", {
      state: {
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  const handleDeleteDeviceConfirmation = (device) => {
    console.log("this is the device data", device);
    setDeleteConfirmation({
      open: true,
      deviceId: device?._id,
      loading: false,
    });
  };
  //   Delete Device Handle
  const handleDeleteDevice = async (device) => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true,
    });
    const res = await DeleteDeviceAPI(deleteConfirmation?.deviceId);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
      setRefreshPage(!refreshPage);
    }
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };
  const handleNotDeleteDevice = () => {
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };

  // the function is use to find the date between start date and last date
  const findByDate = async () => {
    setLoading(true);
    let payload = {
      machineId: machineId,
      startDate: dateback?.startDate,
      endDate: dateback?.endDate,
    };
    let res = await GetDeviceHourReportData(payload);
    if (res?.error != null) {
      setLoading(false);
      toast.error(res.error);
    } else {
      // setDeviceData(res?.data?.result);
      navigate("/dev/report", {
        state: {
          date: sendDate,
          filterDate: res?.data?.result,
          machineName: machine?.title,
          buildingName: buildingName,
          departmentName: departmentName,
        },
        // state: { date: sendDate, filterDate: filterDate },
      });
    }
    // let payload = {
    //   machineId: machineId,
    //   startDate: dateback?.startDate,
    //   endDate: dateback?.endDate,
    // };
    // let res = await GetDeviceHourlyReportData(payload);
    // if (res?.error != null) {
    //   toast.error(res.error);
    // } else {
    //   navigate("/dev/report", {
    //     state: { date: sendDate, filterDate: res?.data?.result },
    //   });
    // }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <>
          <Spin size="large" fullscreen={true} />
        </>
      )}
      <div className="MainDeviceWithFooter">
        <div className="deviceMainContainer">
          <div className="deviceMain">
            <div className="deviceHeader">
              <div className="headleft">
                <BsArrowLeftShort
                  className="icon cursor"
                  onClick={() => navigate(-1)}
                />
                <div className="heading">{`${buildingName}>${departmentName}>${machine?.title}(${devices[0]?.Device_ID})`}</div>
              </div>
              <div className="part2">
                <div className="datepiker">
                  <DateRangePicker
                    showOneCalendar
                    className="rangepiker"
                    onChange={(event) => settingDate(event)}
                    value={value}
                    placeholder="Start Date ~ End Date"
                    renderValue={(value) => {
                      return (
                        moment(value[0])?.format("DD-MM-YYYY") +
                        " ~ " +
                        moment(value[1])?.format("DD-MM-YYYY")
                      );
                    }}
                  />
                  {deviceData.length > 0 ? (
                    <Button
                      className="btnreport EditBtn"
                      type="submit"
                      onClick={findByDate}
                    >
                      {" "}
                      Report
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="btnreport EditBtn"
                      type="submit"
                      onClick={findByDate}
                    >
                      {" "}
                      Report
                    </Button>
                  )}
                </div>
                {[ROLES.Admin, ROLES.SuperAdmin].includes(userData?.role) && (
                  <>
                    {deviceData?.length < 1 ? (
                      <Button className="EditBtn" onClick={handleAddDevice}>
                        Add Device
                      </Button>
                    ) : (
                      <Button
                        className="EditBtn"
                        onClick={() =>
                          handleDeleteDeviceConfirmation(devices[0])
                        }
                      >
                        Delete Device
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            {deviceData.length > 0 ? (
              <div className="deviceData">
                <div className="detailPart1 deviceDetails">
                  <div className="tables">
                    {/* <div className="tablePart1">
                    <table>
                      <tr className="headerRow">
                        <th colSpan={2} className="tableh">
                          GENERATOR
                        </th>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Power kw</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.kw ? firstDevice?.[firstDevice?.length-1]?.kw : "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Total kW/h</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.totalKWH ? firstDevice?.[firstDevice?.length-1]?.totalKWH: "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">3-Phase A</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.current ? firstDevice?.[firstDevice?.length-1]?.current: "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">3-Phase V</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.voltage ? firstDevice?.[firstDevice?.length-1]?.voltage: "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Frequency</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.hz ? firstDevice?.[firstDevice?.length-1]?.hz: "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Power Factor</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.pf ? firstDevice?.[firstDevice?.length-1]?.pf: "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Generator Status</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.Gen_Status ? firstDevice?.[firstDevice?.length-1]?.Gen_Status ==="1" ? "ON" : "OFF" : "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">Genrator Load Status</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.gensetLoadStatus ? firstDevice?.[firstDevice?.length-1]?.gensetLoadStatus==="1" ? "ON" : "OFF" : "-"}</td>
                      </tr>
                      <tr className="bodyRow">
                        <td className="bodyDetail">WAPDA</td>
                        <td className="bodyDetail bodyValues">{firstDevice?.[firstDevice?.length-1]?.wapdaStatus  ? firstDevice?.[firstDevice?.length-1]?.wapdaStatus ==="1" ? "ON" : "OFF" : "-"}</td>
                      </tr>
                    </table>
                  </div> */}
                    <div className="tablePart2">
                      <table>
                        <tr className="headerRow">
                          <th colSpan={2} className="tableh">
                            FUEL
                          </th>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Available Fuel Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.availablefuel
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.availablefuel
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Level %</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.fuellevel
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.fuellevel
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Tank Volume Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.tankvolume
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.tankvolume
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">
                            Total Fuel Consumption Ltr
                          </td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.totalfuelconsumed
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.totalfuelconsumed
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Total Fuel Fill Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.totalrefueling
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.totalrefueling
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">
                            Total Fuel Pilferage Ltr
                          </td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.totalpilferage
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.totalpilferage
                              : "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="tablePart1">
                      <table>
                        <tr className="headerRow">
                          <th colSpan={2} className="tableh">
                            STATUS
                          </th>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Updated Date</td>
                          <td className="bodyDetail bodyValues">
                            {moment(
                              firstDevice?.[firstDevice?.length - 1]?.datetime
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Updated Time</td>
                          <td className="bodyDetail bodyValues">
                            {moment(
                              firstDevice?.[firstDevice?.length - 1]?.datetime
                            ).format("HH:mm")}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Generator Status</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.Gen_Status
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.Gen_Status === "1"
                                ? "ON"
                                : "OFF"
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">WAPDA Status</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.wapdaStatus
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.wapdaStatus === "1"
                                ? "ON"
                                : "OFF"
                              : "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="areaChartSteamFlow">
                    <AreaChart
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      // engineData={firstDevice?.map((data) =>
                      //   parseInt(data?.engineStatus)
                      // )}
                      // labelname1={"Engine Status"}
                      genData={firstDevice?.map((data) =>
                        parseInt(data?.Gen_Status)
                      )}
                      labelname2={"Genset Status"}
                    />
                  </div>
                </div>
                <div className="detailPart2 deviceDetails">
                  <div className="tables">
                    <div className="tablePart1">
                      <table>
                        <tr className="headerRow">
                          <th colSpan={2} className="tableh">
                            LAST ACTIVITY
                          </th>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Consumed Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.fuelconsumed
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.fuelconsumed
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Fill Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.refueling
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.refueling
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Pilferage Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.pilferage
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.pilferage
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">GENSET Start Time</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.gensetOnTime
                              ? (firstDevice?.[
                                  firstDevice?.length - 1
                                ]?.gensetOnTime).slice(0, 5)
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">GENSET Stop Time</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.gensetOffTime
                              ? (firstDevice?.[
                                  firstDevice?.length - 1
                                ]?.gensetOffTime).slice(0, 5)
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">GENSET Run Time M</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.gensetruntime
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.gensetruntime
                              : "-"}
                          </td>
                        </tr>
                        {/* <tr className="bodyRow">
                          <td className="bodyDetail">WAPDA OFF Time M</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.wapdaOffTime
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.wapdaOffTime
                              : "-"}
                          </td>
                        </tr> */}
                      </table>
                    </div>
                    {/* <div className="tablePart2">
                      <table>
                        <tr className="headerRow">
                          <th colSpan={2} className="tableh">
                            ENGINE
                          </th>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Oil Pressure Psi</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.oilpressure
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.oilpressure
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Water Temperature °C</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.watertemp
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.watertemp
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Battery Voltage</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.voltaDC
                              ? firstDevice?.[firstDevice?.length - 1]?.voltaDC
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Total Run Hours M</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.totalGensetRunTime
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.totalGensetRunTime
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Lid Status</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]?.lidStatus
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.lidStatus === "1"
                                ? "OPEN"
                                : "CLOSE"
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Water Level</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.waterLevelStatus
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.waterLevelStatus === "1"
                                ? "OK"
                                : "NOT OK"
                              : "-"}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Engine Status</td>
                          <td className="bodyDetail bodyValues">
                            {firstDevice?.[firstDevice?.length - 1]
                              ?.engineStatus
                              ? firstDevice?.[firstDevice?.length - 1]
                                  ?.engineStatus === "1"
                                ? "RUN"
                                : "OFF"
                              : "-"}
                          </td>
                        </tr>
                      </table>
                    </div> */}
                    <div className="tablePart3">
                      <table>
                        <tr className="headerRow">
                          <th colSpan={2} className="tableh">
                            DAILY ACTIVITY
                          </th>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Consumed Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {countFuelConsumed ? countFuelConsumed : 0}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Fill Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {countRefueling ? countRefueling : 0}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Pilferage Ltr</td>
                          <td className="bodyDetail bodyValues">
                            {countPilferage ? countPilferage : 0}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">
                            Total Genset Run Time M
                          </td>
                          <td className="bodyDetail bodyValues">
                            {countGenSetRunTime ? countGenSetRunTime : 0}
                          </td>
                        </tr>
                        <tr className="bodyRow">
                          <td className="bodyDetail">Fuel Average Ltr/h</td>
                          <td className="bodyDetail bodyValues">
                            {averageFuel
                              ? countGenSetRunTime !== 0
                                ? parseFloat(averageFuel).toFixed(1)
                                : 0
                              : 0}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="areaChartSteamFlow">
                    <AreaChart1
                      labels={firstDevice?.map((time) =>
                        moment(time?.datetime).format("DD |HH:mm")
                      )}
                      availableFuel={firstDevice?.map((data) =>
                        parseInt(data?.availablefuel)
                      )}
                      labelname1={"Available Fuel"}
                      fuelFill={firstDevice?.map((data) =>
                        parseInt(data?.refueling)
                      )}
                      labelname2={"Fuel Fill"}
                      fuelConsumed={firstDevice?.map((data) =>
                        parseInt(data?.fuelconsumed)
                      )}
                      labelname3={"Fuel Consumed"}
                      pilferage={firstDevice?.map((data) =>
                        parseInt(data?.pilferage)
                      )}
                      labelname4={"Pilferage"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="noValue">No Data Found</div>
            )}
          </div>
        </div>
      </div>

      <ConfirmationModel
        open={deleteConfirmation.open}
        onOk={handleDeleteDevice}
        onCancel={handleNotDeleteDevice}
        confirmLoading={deleteConfirmation.loading}
        test={deleteConfirmation?.deviceId}
      >
        <div className="deleteModel">
          <div className="titleBox">
            <RiInformationLine className="icon" />{" "}
            <div className="title">
              {" "}
              Are you sure you want to delete this Device?{" "}
            </div>
          </div>
        </div>
      </ConfirmationModel>
    </>
  );
}
