import React from 'react'
// import './Header.css'
import './HeaderLogin.css'
import idc from '../../../Asserts/Logo/idc.png'
import Logout from '../Logout/Logout'
import { useSelector } from 'react-redux'
import ROLES from '../../../Utils/Roles'



export default function HeaderLogin(props) {

    const userData = useSelector((state)=>state?.userData)

    return (
        <>
            <div className="machineHeaderLogin">
                <img className='machineimagelogoLogin' src={idc} alt="Uploading" />
                <div className="machineheadingLogin"><span className='boldmachineheadingLogin'> MRS9000</span> Monitoring & Reporting System</div>
                <div className="headerright">
                <div className="datetimeupdatedLogin">{props.timedate}</div>
                </div>
            </div>
        </>
    )
}
