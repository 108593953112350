import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function AreaChart1(props) {
  const options = {
    type: "bar",
    responsive: true,
    maintainAspectRatio: false,
    backgroundColor: "transparent",
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
            // family: "vazir",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            // family: "vazir",
          },
        },
        beginAtZero: true,
        max: 1,
      },
    },
  };
  const labels = props.labels;
  const label2 = props.labelname2;
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: label2,
        data: props.genData,
        borderColor: "rgb(169,209,142)",
        backgroundColor: "rgba(169,209,142, 8)",
        radius: 3,
        borderJoinStyle: "round",
      },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} height={285} width={350} />
    </>
  );
}

// import React from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend
// );

// export default function AreaChart(props) {
//   const options = {
//     maintainAspectRatio: false,
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         max: props.maxValueArea,
//       },
//     },
//   };
//   const labels = props.labels;

//   const label1 = props.labelname1;
//   const label2 = props.labelname2;

//   const data = {
//     labels,
//     datasets: [
//       // {
//       //     fill: false,
//       //     label: label1,
//       //     data: props.engineData,
//       //     borderColor: 'rgb(48, 25, 52)',
//       //     // backgroundColor: 'rgba(169, 204, 227, 0.7)',
//       //     radius: 3,
//       //     borderJoinStyle: 'round',
//       // },
//       {
//         fill: true,
//         label: label2,
//         data: props.genData,
//         borderColor: "rgb(169,209,142)",
//         backgroundColor: "rgba(169,209,142, 8)",
//         borderJoinStyle: "round",
//       },
//     ],
//   };
//   return (
//     <>
//       <Line options={options} data={data} height={285} width={350} />
//     </>
//   );
// }
