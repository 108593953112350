import React from "react";
import "./ReportMachineHourly.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import idcLogo from "../../Asserts/Logo/idc.png";

const ReportMachineHourly = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date;
  const roomName = location?.state?.roomName;
  let arr = location?.state?.filterDateHour;
  const machineName = location?.state?.machineName;
  const buildingName = location?.state?.buildingName;
  const departmentName = location?.state?.departmentName;
  const array = arr[0];

  let engineStatusCount = 0;
  let engineStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].engineStatus;
    if (engineStatusPreviousValue === "0" && currentValue === "1") {
      engineStatusCount++;
    }
    engineStatusPreviousValue = currentValue;
  }

  let genSetStatusCount = 0;
  let genSetStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].Gen_Status;
    if (genSetStatusPreviousValue === "0" && currentValue === "1") {
      genSetStatusCount++;
    }
    genSetStatusPreviousValue = currentValue;
  }

  let wapdaStatusCount = 0;
  let wapdaStatusPreviousValue = null;

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i].wapdaStatus;
    if (wapdaStatusPreviousValue === "1" && currentValue === "0") {
      wapdaStatusCount++;
    }
    wapdaStatusPreviousValue = currentValue;
  }

  const reportformat = `IDC_${buildingName}_branches_${departmentName}_Genset_350KVA_Event_Report_${date}`;
  const reportLocation = `${buildingName}>${departmentName}>${machineName} (${array[0]?.Device_ID})>Daily Report>Event Report (${date})`;
  const pdfbtn = () => {
    var doc = new jsPDF("landscape", "pt", "a2");
    var content = document.getElementById("reportid");

    const margin = 20;

    doc.html(content, {
      x: 5,
      y: margin + 60,
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const logo = idcLogo;
        const logoWidth = 80;
        const logoHeight = 40;

        doc.setFontSize(16);

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);

          doc.addImage(
            logo,
            "PNG",
            margin + 5,
            margin + 5,
            logoWidth,
            logoHeight
          );
          const gensetText = "GENSET Health Monitoring System";
          const gensetTextWidth = doc.getTextWidth(gensetText);
          doc.text(
            gensetText,
            (pageWidth - gensetTextWidth) / 2,
            margin + 50 / 2
          );

          const reportTextWidth = doc.getTextWidth(reportformat);
          doc.text(
            reportLocation,
            (pageWidth - reportTextWidth) / 2,
            margin + 50
          );

          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            pageWidth - margin,
            pageHeight - margin,
            { align: "right" }
          );
        }
        doc.save(reportformat);

        return pageCount;
      },
      margin: [margin, margin, margin, margin],
    });
  };

  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">{`${buildingName}>${departmentName}>${machineName} (${array[0]?.Device_ID})>Daily Report>Event Report (${date})`}</div>
        </div>
        <div className="report" id="reportid">
          <div className="reportsMainContainer">
            <>
              <table className="tablemain" id="tablee">
                <thead className="tablehead">
                  <tr>
                    {array && array[array?.length - 1]?.datetime && (
                      <th className="tableh" rowSpan="2">
                        Time | Date
                      </th>
                    )}
                  </tr>
                  {array && (
                    <tr className="tablerow">
                      <th className="tableh">Total Fuel Consumption</th>
                      <th className="tableh">Total Fuel Fill</th>
                      <th className="tableh">Total Fuel Pilferage</th>
                      <th className="tableh">Total Genset Run Hours</th>
                      <th className="tableh">Genset Status</th>
                      <th className="tableh">WAPDA Status</th>
                      <th className="tableh">Available Fuel</th>
                      <th className="tableh">Fuel Level</th>
                      <th className="tableh">Tank Volume</th>
                      <th className="tableh">Fuel Consumed</th>
                      <th className="tableh">Fuel Fill</th>
                      <th className="tableh">Fuel Pilferage</th>
                      <th className="tableh">Genset ON Time</th>
                      <th className="tableh">Genset OFF Time</th>
                      <th className="tableh">Genset Run Time</th>
                    </tr>
                  )}
                </thead>
                <tbody className="tablebody">
                  {array?.map((ddd, i, newArray) => {
                    let previousTotal = i > 0 ? newArray[i - 1]?.totalizer : 0;
                    let difference = ddd?.totalizer - previousTotal;
                    return (
                      <tr className="tablerow" key={i}>
                        {array && (
                          <td className="tabled dateField">
                            {moment(ddd?.datetime).format("HH:mm | DD-MM-YYYY")}
                          </td>
                        )}
                        {array[array?.length - 1]?.totalfuelconsumed && (
                          <td className="tabled">{ddd?.totalfuelconsumed}</td>
                        )}
                        {array[array?.length - 1]?.totalrefueling && (
                          <td className="tabled">{ddd?.totalrefueling}</td>
                        )}
                        {array[array?.length - 1]?.totalpilferage && (
                          <td className="tabled">{ddd?.totalpilferage}</td>
                        )}
                        {array[array?.length - 1]?.totalGensetRunTime && (
                          <td className="tabled">{ddd?.totalGensetRunTime}</td>
                        )}
                        {array[array?.length - 1]?.Gen_Status && (
                          <td className="tabled">
                            {ddd?.Gen_Status === "1" ? "ON" : "OFF"}
                          </td>
                        )}
                        {array[array?.length - 1]?.wapdaStatus && (
                          <td className="tabled">
                            {ddd?.wapdaStatus === "1" ? "ON" : "OFF"}
                          </td>
                        )}

                        {array[array?.length - 1]?.availablefuel && (
                          <td className="tabled">{ddd?.availablefuel}</td>
                        )}
                        {array[array?.length - 1]?.fuellevel && (
                          <td className="tabled">{ddd?.fuellevel}</td>
                        )}
                        {array[array?.length - 1]?.tankvolume && (
                          <td className="tabled">{ddd?.tankvolume}</td>
                        )}
                        {array[array?.length - 1]?.fuelconsumed && (
                          <td className="tabled">{ddd?.fuelconsumed}</td>
                        )}
                        {array[array?.length - 1]?.refueling && (
                          <td className="tabled">{ddd?.refueling}</td>
                        )}
                        {array[array?.length - 1]?.pilferage && (
                          <td className="tabled">{ddd?.pilferage}</td>
                        )}
                        {array[array?.length - 1]?.gensetruntime && (
                          <td className="tabled">
                            {ddd?.gensetOnTime
                              ? (ddd?.gensetOnTime).slice(0, 5)
                              : "-"}
                          </td>
                        )}
                        {array[array?.length - 1]?.gensetruntime && (
                          <td className="tabled">
                            {ddd?.gensetOffTime
                              ? (ddd?.gensetOffTime).slice(0, 5)
                              : "-"}
                          </td>
                        )}
                        {array[array?.length - 1]?.gensetruntime && (
                          <td className="tabled">{ddd?.gensetruntime}</td>
                        )}
                      </tr>
                    );
                  })}
                  <tr className="tablerow">
                    {array && <th className="tableh tablehead">Total</th>}
                    {array[array?.length - 1]?.totalfuelconsumed && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalfuelconsumed -
                            array?.[0]?.totalfuelconsumed
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalrefueling && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalrefueling -
                            array?.[0]?.totalrefueling
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalpilferage && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalpilferage -
                            array?.[0]?.totalpilferage
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalGensetRunTime && (
                      <td className="tableh tablehead">
                        {parseInt(
                          array[array?.length - 1]?.totalGensetRunTime -
                            array?.[0]?.totalGensetRunTime
                        ).toLocaleString()}
                      </td>
                    )}
                    {array[array?.length - 1]?.totalGensetRunTime && (
                      <td className="tableh tablehead">{genSetStatusCount}</td>
                    )}
                    {array[array?.length - 1]?.totalGensetRunTime && (
                      <td className="tableh tablehead">{wapdaStatusCount}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </div>

        <div className="areaChartSteamFlow">
          <AreaChart1
            labels={array?.map((time) =>
              moment(time?.datetime).format("DD |HH:mm")
            )}
            availableFuel={array?.map((data) => parseInt(data?.availablefuel))}
            labelname1={"Available Fuel"}
            fuelFill={array?.map((data) => parseInt(data?.totalrefueling))}
            labelname2={"Total Fuel Fill"}
            fuelConsumed={array?.map((data) =>
              parseInt(data?.totalfuelconsumed)
            )}
            labelname3={"Total Fuel Consumed"}
            pilferage={array?.map((data) => parseInt(data?.totalpilferage))}
            labelname4={"Total Pilferage"}
          />
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachineHourly;
